import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout/layout.js";

import Helmet from "react-helmet";
import { marked } from 'marked';

import PageContainer from "../components/layout/page-container";
import PageSection from "../components/layout/page-section";

import FullSignupForm from "../components/newsletter/full-signup-form";

class BlogPost extends React.Component {
  render() {
    const hasHeaderImage = (this.props.data.markdownRemark.frontmatter.header_img_path) ? true : false;

    return (
      <Layout>
        <PageContainer>
          <Helmet>
            <title>{this.props.data.markdownRemark.frontmatter.title}</title>
            <meta name="title" content={this.props.data.markdownRemark.frontmatter.title} />
            <meta name="description" content={this.props.data.markdownRemark.frontmatter.summary} />

            <meta property="og:type" content="article" />
            <meta property="og:url" content={`https://local-memory.org/notes${this.props.data.markdownRemark.fields.slug}`} />
            <meta property="og:title" content={this.props.data.markdownRemark.frontmatter.title} />
            <meta property="og:description" content={this.props.data.markdownRemark.frontmatter.summary} />
            <meta property="og:image" content={`https://local-memory.org/images/blog/${this.props.data.markdownRemark.frontmatter.feature_img_path}`} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={`https://local-memory.org/notes${this.props.data.markdownRemark.fields.slug}`} />
            <meta property="twitter:title" content={this.props.data.markdownRemark.frontmatter.title} />
            <meta property="twitter:description" content={this.props.data.markdownRemark.frontmatter.summary} />
            <meta property="twitter:image" content={`https://local-memory.org/images/blog/${this.props.data.markdownRemark.frontmatter.feature_img_path}`} />
          </Helmet>
          <PageSection background="dark-accent" wrapperType="wrapper">
            <div className="blog-section-title">
              <Link to="/notes">
                Notes From a Limestone Ledge
              </Link>
            </div>
          </PageSection>
          <PageSection background="gray" wrapperType="wrapper">
            {(hasHeaderImage) ? (
              <img 
                className="blog-post-feature-image"
                src={`/images/blog/${this.props.data.markdownRemark.frontmatter.header_img_path}`} 
                alt={this.props.data.markdownRemark.frontmatter.header_img_alt}
              />
            ) : null}
          </PageSection>
          <PageSection topPadding="sm" bottomPadding="lg">
            <article 
              className={"blog-post" + (hasHeaderImage ? " has-header-image" : "")}>
              <h1
                className="blog-title"
                dangerouslySetInnerHTML={
                  { __html:
                    marked(this.props.data.markdownRemark.frontmatter.title).replace('<p>', '').replace('</p>', '')
                  }}
              />
              <div className="blog-meta">
                <span className="detail">{this.props.data.markdownRemark.frontmatter.author}</span>
                <span className="detail">{this.props.data.markdownRemark.frontmatter.date}</span>
              </div>
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{__html: this.props.data.markdownRemark.html}} />
            </article>
          </PageSection>

          <PageSection background="gray" topPadding="md" bottomPadding="md">
            <FullSignupForm />
          </PageSection>

        </PageContainer>
      </Layout>
    );
  }
}

export default BlogPost;

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      frontmatter {
        title
        author
        date(formatString: "MMMM D, YYYY")
        feature_img_path
        header_img_path
        header_img_alt
      }
      html
      fields {
        slug
      }
    }
  }
`;